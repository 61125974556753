<template>
  <div class="work-school-recommend">
    <JobWantedHead />
    <div class="recommend-main">
      <div class="main-accurate">
        <div class="titleTop">
          <el-image
            class="accurate-img"
            :src="require(`@/assets/image/accurate.png`)"
            fit="contain"
          ></el-image>

          <el-cascader
            placeholder="请选择省/市"
            v-model="cityData"
            :options="cityOptions"
            @change="onSearch"
            class="cascader"
          ></el-cascader>
          <el-input
            :placeholder="
              selectInput === '1'
                ? '请输入岗位名称'
                : '请输入公司地区或详细地址'
            "
            v-model="searchInput"
            class="input-with-select"
          >
            <el-select
              v-model="selectInput"
              slot="prepend"
              class="input-select"
            >
              <el-option label="岗位名称" value="1"></el-option>
              <el-option label="公司地址" value="2"></el-option>
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="onSearch"
            ></el-button>
          </el-input>
        </div>
        <div class="accurate-data">
          <el-row :gutter="10">
            <el-col
              :xs="24"
              :sm="24"
              :md="12"
              :lg="8"
              :xl="6"
              v-for="item in preciseRecommendList"
              :key="item.shop_user_job_id"
            >
              <div class="col-data" @click="handleDetail(item)">
                <div class="data-top">
                  <div class="top-left">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.job_title"
                      placement="top-start"
                    >
                      <div class="title">{{ item.job_title }}</div>
                    </el-tooltip>
                    <div class="subhead">
                      {{ item.job_city }} {{ item.job_age }}
                      {{ item.job_education }}
                    </div>
                  </div>
                  <div class="top-right">
                    <div class="salary">{{ item.job_salary }}</div>
                    <div class="button-div">
                      <el-button
                        type="primary"
                        size="mini"
                        @click.stop="postJob({ ...item, is_send: 1 })"
                        >投递简历</el-button
                      >
                      <el-button
                        type="warning"
                        size="mini"
                        @click.stop="postJob({ ...item, is_send: 0 })"
                        >立即沟通</el-button
                      >
                    </div>
                  </div>
                </div>
                <div class="data-bottom">
                  <div class="bottom-left">
                    <el-image
                      class="img"
                      :src="item.pic"
                      fit="contain"
                    ></el-image>
                    <span>{{ item.chain_name }}</span>
                  </div>
                  <div>{{ item.job_keyword }} | {{ item.job_duration }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="accurate-button">
          <div class="button" @click="getJobListFn()">
            <i class="el-icon-refresh"></i>
            <span>换一换</span>
          </div>
        </div>
      </div>
      <section class="main-dim">
        <el-image
          class="accurate-img"
          :src="require(`@/assets/image/dim.png`)"
          fit="contain"
        ></el-image>
        <el-row class="dim-data">
          <el-col :span="18" style="padding-right: 20px">
            <div class="dim-data-left">
              <el-row
                class="recommend-card"
                v-for="item in indistinctRecommendList"
                :key="item.shop_user_job_id"
                @click.native="handleDetail(item)"
              >
                <el-col
                  :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="10"
                  :xl="12"
                  class="recommend-card__left"
                >
                  <div class="flex-1">
                    <div class="title">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="
                          item.job_title + '(' + item.job_describe + ')'
                        "
                        placement="top-start"
                      >
                        <h5>{{ item.job_title }}({{ item.job_describe }})</h5>
                      </el-tooltip>
                      <span class="company-address"
                        >[{{ item.job_city }}·{{ item.job_district }}·{{
                          item.job_street
                        }}]</span
                      >
                    </div>
                    <div class="salary-info">
                      <span class="salary">
                        {{ item.job_salary }}·{{ item.job_salary_constitute }}
                      </span>
                      <span
                        class="job-tag ml-1"
                        v-for="tag in item.job_keyword.split(',')"
                        :key="tag"
                        >{{ tag }}</span
                      >
                    </div>
                  </div>
                  <div class="bottom">
                    <!-- Add company tags or additional info if available -->
                  </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="12">
                  <el-row>
                    <el-col :span="18" class="recommend-card__center">
                      <el-row>
                        <el-col :span="8" class="company-image">
                          <el-image :src="item.pic" alt="" />
                        </el-col>
                        <el-col :span="15">
                          <div class="company-name">{{ item.chain_name }}</div>
                          <el-row
                            type="flex"
                            class="row-bg"
                            justify="space-between"
                          >
                            <el-col :span="7" class="job-tag">{{
                              item.job_industry || "未提供"
                            }}</el-col>
                            <el-col :span="7" class="job-tag">{{
                              item.job_financing || "未提供"
                            }}</el-col>
                            <el-col :span="7" class="job-tag">{{
                              item.job_scale || "未提供"
                            }}</el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                      <div class="bottom">
                        <!-- Add employee benefits or additional info if available -->
                      </div>
                    </el-col>
                    <el-col :span="6" class="recommend-card__right">
                      <el-button
                        type="warning"
                        @click.stop="postJob({ ...item, is_send: 0 })"
                        >立即沟通</el-button
                      >
                      <el-button
                        type="primary"
                        @click.stop="postJob({ ...item, is_send: 1 })"
                        >投递简历</el-button
                      >
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="6" class="dim-data-right history-card">
            <header class="info-card-header">看过的职位</header>
            <ul class="info-card-content">
              <li
                class="history-item mt-1"
                v-for="item in getlookUP"
                :key="item.id"
                @click="handleDetail(item)"
              >
                <div class="flex mb-1 history-item__top">
                  <div class="title">{{ item.job_title }}</div>
                  <span class="salary">
                    {{ item.job_salary }}K·{{ item.job_salary_constitut }}薪
                  </span>
                </div>
                <div class="company-info font-grey flex">
                  <span>{{ item.chain_name }}</span>
                  <!-- 错误位置 -->
                  <span class="company-address"
                    >{{ item.job_district }}·{{ item.job_street }}</span
                  >
                </div>
              </li>
            </ul>
          </el-col>
        </el-row>
      </section>
    </div>
  </div>
</template>

<script>
import JobWantedHead from "@/components/JobWantedHead.vue";
import {
  personalInsertCommunicate,
  getJobList,
  getRecommendList,
  getCommunicate,
} from "@/request/api";

import Rogion from "@/assets/json/region.json";

export default {
  name: "WorkSchoolRecommend",
  components: {
    JobWantedHead,
  },
  data() {
    return {
      preciseRecommendList: [], // 精准推荐列表
      indistinctRecommendList: [], // 模糊推荐列表
      // 看过的职位list
      history: [],
      getlookUP: [],
      cityOptions: [], //省市的数据
      cityData: [], // 当前选中的城市
      searchInput: "",
      selectInput: "1",
    };
  },
  created() {
    // window.localStorage.removeItem('history')
    // 进入页面时判断是否存在历史查看list,是的话给history赋值
    const _history = window.localStorage.getItem("history");
    if (_history) {
      this.history = JSON.parse(_history);
    }

    const keys = Object.keys(Rogion);
    //获取第一层级省级
    const options = keys
      .map((key) => {
        if (Rogion.province[key]) {
          return { value: key, label: Rogion.province[key] };
        }
      })
      .filter(Boolean);
    //转换为符合规定的格式
    const citys = options.map((item) => {
      const children = Object.keys(Rogion[item.value]);
      item.children = children.map((key) => {
        return { value: key, label: Rogion[item.value][key] };
      });
      return item;
    });
    this.cityOptions = citys;
    // 初始挂载
    // this.recommendListFn();
    // this.getJobListFn();
    this.getlookUPfn();
    this.onSearch();
  },
  methods: {
    sendExit() {
      this.$emit("update-value");
    },
    // 除以1000,取整
    divSalary(count) {
      return Math.round(count / 1000);
    },
    //  点击推荐list, 加入到查看历史 list内,再跳到详情
    handleDetail(row) {
      this.getlookUPfn();
      this.setHistoryList(row);
      this.$router
        .push({
          path: "/jobDetail",
          query: { shop_user_job_id: row.shop_user_job_id },
        })
        .catch((e) => {
          console.error(e);
        });
    },
    setHistoryList(row) {
      const hasRepeat = this.history.some((item) => item.id === row.id);
      if (!hasRepeat) {
        this.history.push(row);
        window.localStorage.setItem("history", JSON.stringify(this.history));
      }
    },
    // 立即沟通
    handleChat(row) {
      this.getlookUPfn();
      // 存入查看历史列表后，跳到立即沟通界面，query带上招聘信息
      this.setHistoryList(row);
      // alert("立即沟通");

      this.$router
        .push({
          path: "/workChat",
          query: { shop_user_job_id: row.shop_user_job_id },
        })
        .catch((e) => {
          console.error(e);
        });
    },
    // 投递简历|立即沟通
    postJob(row) {
      // let url = "https://www.bmbai.top/api/shopApi/chatGPT/insertCommunicate";
      let params = {
        shop_user_job_id: row.shop_user_job_id,
        is_send: row.is_send,
      };
      personalInsertCommunicate(params)
        .then((res) => {
          if (res.data.code === "1000") {
            // this.indistinctRecommendList = res.data.data;
            if (row.is_send) {
              this.$message({
                message: "成功投递！",
                type: "success",
              });
            }
            this.handleChat(row);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    async onSearch() {
      const body = {
        job_city: "",
        job_district: "",
        job_title: "",
        job_street: "",
      };
      if (this.cityData[0]) {
        body.job_city = Rogion.province[this.cityData[0]];
        body.job_district = Rogion[this.cityData[0]][this.cityData[1]];
      }
      if (this.selectInput === "1") {
        body.job_title = this.searchInput;
      } else {
        body.job_street = this.searchInput;
      }
      const res = await getJobList(body);
      const ret = await getRecommendList(body);
      if (res.data.code === "1000") {
        this.preciseRecommendList = res.data.data;
      }

      if (ret.data.code === "1000") {
        this.indistinctRecommendList = ret.data.data;
      }
    },

    recommendListFn() {
      //加载模糊推荐职位列表
      getRecommendList().then((res) => {
        if (res.data.code === "1000") {
          this.indistinctRecommendList = res.data.data;
        }
      });
    },
    //精准推荐职位列表
    getJobListFn() {
      getJobList().then((res) => {
        if (res.data.code === "1000") {
          this.preciseRecommendList = res.data.data;
        }
      });
    },
    getlookUPfn() {
      getCommunicate().then((res) => {
        if (res.data.code === "1000") {
          this.getlookUP = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.work-school-recommend {
  .job-tag {
    white-space: nowrap; //文本不会换行
  }
  box-sizing: border-box;
  // min-width: 1000px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  .recommend-main {
    height: 92%;

    .main-dim {
      // height: calc(100% - 330px);

      .accurate-img {
        height: 30px;
        margin-bottom: 10px;
      }

      .dim-data {
        // height: calc(100% - 40px);
        display: flex;

        .dim-data-left {
          height: 100%;
          overflow-y: auto;
        }

        .dim-data-right.history-card {
          height: 100%;
          border-radius: 8px;
          box-sizing: border-box;

          .info-card-content {
            max-height: calc(100% - 40px);
            overflow-y: auto;

            .history-item {
              .history-item__top {
                .title {
                  font-weight: bold;
                }

                justify-content: space-between;

                .salary {
                  color: #f4a933;
                }
              }

              .company-info {
                justify-content: space-between;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .main-accurate {
      // height: 330px;
      .titleTop {
        display: flex;
        align-items: center;
      }
      .accurate-img {
        height: 30px;
      }
      .cascader {
        margin: 0 20px;
      }
      .input-with-select {
        width: 400px;
        // color: #4d94ff;
        // // border-color: #4d94ff;
        // /deep/.el-input-group__append {
        //   border-color: #4d94ff;
        //   color: #4d94ff;
        // }
        // /deep/.el-input-group__prepend {
        //   color: #4d94ff;
        //   border-color: #4d94ff;
        // }
        // .el-input-group__prepend {
        //   /deep/.el-input__inner {
        //     border-color: transparent !important;
        //   }
        // }
        // /deep/.el-input__inner {
        //   border-color: #4d94ff;
        // }
      }
      .input-select {
        width: 120px;
      }
      .accurate-data {
        // height: 260px;

        .el-row {
          height: 100%;

          .el-col {
            height: 50%;
            padding: 1%;

            .col-data {
              background-color: #fff;
              height: 100%;
              border-radius: 4px;
              box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
              display: flex;
              flex-direction: column;
              cursor: pointer;
              .data-top {
                height: 70%;
                padding: 12px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;

                .top-left {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;

                  .title {
                    width: 100%;
                    font-size: 18px;
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis; //文本溢出显示省略号
                    white-space: nowrap; //文本不会换行
                  }

                  .subhead {
                    font-size: 14px;
                  }
                }

                .top-right {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  justify-content: space-between;

                  .salary {
                    color: #f4a933;
                    font-weight: bold;
                  }

                  .button-div {
                    display: flex;
                  }
                }
              }

              .data-bottom {
                height: 30%;
                background-color: #cce1ff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 12px;
                font-size: 14px;

                .bottom-left {
                  display: flex;
                  align-items: center;

                  .img {
                    width: 30px;
                  }
                }
              }
            }
          }
        }
      }

      .accurate-button {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 1%;

        .button {
          font-size: 14px;
          color: #3e83ff;
          cursor: pointer;
          border-bottom: 1px solid #2a6ee9;
        }
      }
    }
  }
  /*推荐列表*/
  .recommend-card {
    position: relative;
    box-sizing: border-box;
    padding: 20px 20px 0 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 8px;
    cursor: pointer;
    &:before {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 23px;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(
        to right,
        rgba(230, 240, 255, 1),
        rgba(230, 240, 255, 0)
      );
      content: "";
      border-radius: 0 0 8px 8px;
    }

    .bottom {
      font-size: 13px;
      height: 23px;
      line-height: 23px;
    }

    .recommend-card__left {
      .title {
        margin-bottom: 10px;

        h5 {
          width: 100%;
          margin: 0;
          display: inline-block;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          white-space: nowrap; //文本不会换行
        }
      }

      .company-address {
        margin-left: 3em;
        font-size: 16px;
      }
    }

    .salary {
      color: #f4a933;
      font-size: 18px;
    }

    // 中间
    .recommend-card__center {
      // width: 40%;
      // min-width: 400px;

      .company-image {
        .el-image {
          width: 90px;
          height: 90px;
          border-radius: 8px;
        }
      }

      .company-name {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }

    .recommend-card__right {
      .el-button {
        margin: 0;

        & + .el-button {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
